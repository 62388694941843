@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary-50: 252, 244, 246;
    --color-primary-100: 249, 234, 236;
    --color-primary-200: 243, 216, 221;
    --color-primary-300: 233, 184, 194;
    --color-primary-400: 222, 148, 165;
    --color-primary-500: 204, 103, 129;
    --color-primary-600: 182, 72, 105;
    --color-primary-700: 152, 56, 87;
    --color-primary-800: 128, 49, 78;
    --color-primary-900: 110, 45, 71;
    --color-primary-950: 60, 21, 35;
    --color-secondary-50: 238, 252, 253;
    --color-secondary-100: 212, 245, 249;
    --color-secondary-200: 174, 234, 243;
    --color-secondary-300: 118, 218, 234;
    --color-secondary-400: 55, 192, 217;
    --color-secondary-500: 26, 156, 183;
    --color-secondary-600: 25, 130, 161;
    --color-secondary-700: 27, 105, 131;
    --color-secondary-800: 31, 87, 107;
    --color-secondary-900: 30, 72, 91;
    --color-secondary-950: 14, 47, 62;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-semibold;
  }
}
